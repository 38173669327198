import React from "react";

var bgimg1 = require("./../../images/background/bg-5.png");
var bgimg2 = require("./../../images/background/bg-2.jpg");
var bgimg3 = require("./../../images/background/cross-line2.png");

class About5 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding bg-white p-t80 p-b30 bg-repeat overflow-hide"
          style={{ backgroundImage: `url(${bgimg1})` }}
        >
          <div className="container right-half-bg-image-outer">
            {/* <div
              className="right-half-bg-image bg-parallax bg-fixed bg-top-right"
              data-stellar-background-ratio={0}
              style={{ backgroundImage: `url(${bgimg2})` }}
            /> */}
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: `url(${bgimg3})` }}>
                  <h3 className="sep-line-one">
                    BIM LOD 100 to 500 Level of Development
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="row number-block-one-outer justify-content-center">
                {[
                  { img: "LOD-100.jpg", title: "LOD 100" },
                  { img: "LOD-200.jpg", title: "LOD 200" },
                  { img: "LOD-300.jpg", title: "LOD 300" },
                  { img: "LOD-400.jpg", title: "LOD 400" },
                  { img: "LOD-500.jpg", title: "LOD 500" },
                ].map((item, index) => (
                  <div key={index} className="col-lg-4 col-md-6 col-sm-6 m-b30">
                    <div className="img-box">
                      <img
                        className="img-responsive"
                        style={{
                          height: "200px",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        src={require(`./../../images/${item.img}`)}
                        alt={item.title}
                      />
                      <div className="figcaption bg-white text-center p-a20">
                        <h4 className="m-a0">{item.title}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <style jsx>{`
          .img-box {
            transition: transform 0.3s ease;
          }
          .img-box:hover {
            transform: scale(1.05);
          }
          .figcaption h4 {
            // color: #333;
            font-weight: bold;
            transition: color 0.3s ease;
          }
          .figcaption:hover h4 {
            // color: #007bff;
          }
        `}</style>
      </>
    );
  }
}

export default About5;
