import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/background/bg5.gif");

class About2 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t60 p-b60 bg-gray">
          <div className="container">
            <div className="section-content">
              <div className="row d-flex align-items-stretch">
                <div className="col-xl-5 col-lg-5 col-md-12 d-flex">
                  <div
                    className="home-2-about flex-grow-1 bg-no-repeat bg-cover responsive-image"
                    style={{
                      backgroundImage: "url(" + bgimg1 + ")",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      padding: "0", // Reduce top padding
                    }}
                  ></div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-12 d-flex">
                  <div className="about-home-2 flex-grow-1 d-flex flex-column justify-content-center">
                    <h3 className="m-t0 sx-tilte">
                      Revolutionizing Construction: Unleashing the Power of BIM Solutions
                    </h3>
                    <p>
                      "Revolutionizing Construction: Unleashing the Power of BIM Solutions" highlights the transformative role of Building Information Modeling (BIM) in the construction industry. BIM is a digital representation of physical and functional characteristics of a facility, enabling enhanced collaboration, accuracy, and efficiency throughout a project's lifecycle. By unlocking the potential of BIM, construction teams can streamline processes, reduce errors, and make data-driven decisions, leading to cost savings and improved project outcomes. This approach revolutionizes traditional construction practices, setting a new standard for innovation and productivity in the industry.
                    </p>
                    <div className="text-left">
                      <NavLink to={"/about-2"} className="site-button-link">
                        Read More
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About2;
