import React from "react";
import { NavLink } from "react-router-dom";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import ReactPlayer from "react-player";

var bnrimg = require("./../../images/banner/6.jpg");

class ServiceDetail extends React.Component {
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner
            title="Service Details"
            pagename="Service Detail"
            description="Speed Up Project Delivery with 3D BIM Modeling Services"
            bgimage={bnrimg}
          />
          {/* SECTION CONTENT START */}
          <div className="section-full p-t80 p-b50 mobile-page-padding">
            <div className="container">
              {/* GALLERY CONTENT START */}
              <div className="row">
                <div className="col-lg-4 col-md-12 sticky_column">
                  <div className="about4-section m-b20 bg-gray p-a20">
                    <div className="widget widget_services">
                      <h4 className="widget-title">More Services</h4>
                      <ul className="bg-white">
                        <li>
                          <NavLink to={"/services-1"}>3D Modeling</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/services-1"}>4D/5D Construction Simulation</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/services-1"}>VR/AR Based Construction Services</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/services-1"}>Architectural Design</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/services-1"}>MEP Design Services</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/services-1"}>Building Energy Optimization</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/services-1"}>Structural Analysis</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/services-1"}>Quantity Takeoff</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/services-1"}>Marketing Presentations</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/services-1"}>Scan to BIM</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/services-1"}>Digital Prefabrication</NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                      <NavLink to={"/services-1"} className="btn-block">
                        <span className="text-black m-r10">
                          <i className="fa fa-file-pdf-o" />
                        </span>
                        <strong className="text-uppercase text-black">
                          Download .PDF
                        </strong>
                      </NavLink>
                    </div>
                    <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                      <NavLink to={"/services-1"} className="btn-block">
                        <span className="text-black m-r10">
                          <i className="fa fa-file-word-o" />
                        </span>
                        <strong className="text-uppercase text-black">
                          {" "}
                          Download .DOC
                        </strong>
                      </NavLink>
                    </div>
                    <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                      <NavLink to={"/services-1"} className="btn-block">
                        <span className="text-black m-r10">
                          <i className="fa fa-file-powerpoint-o" />
                        </span>
                        <strong className="text-uppercase text-black">
                          Download .PPT
                        </strong>
                      </NavLink>
                    </div>
                    <div className="about4-content m-t30">
                      <h4>GET 10% OFF TODAY</h4>
                      <p>
                        A Completely Safe and Advanced Cleaning Solution for
                        both Petrol and Diesel Engines
                      </p>
                      <NavLink to={"/services-1"} className="site-button-link">
                        Read More
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="section-content">
                    <div className="service-single-block m-b30">
                      <div className="sx-media">
                        <img
                          src={require("./../../images/gallery/pic5.jpg")}
                          alt=""
                        />
                      </div>
                      <h3 className="m-t30 sx-tilte">
                      BIM Coordination Process in Modern Construction
                      </h3>
                      <p>
                      Managing complex construction projects that have heavy processes and multidisciplinary trades involved demand specialized solutions. A complex project may require a number of professionals to collaborate on each task while working in discrete processes across phases keeping it aligned with project goals.
                      </p>
                      <p>
                      In such projects BIM coordination services play a pivotal role as it brings together the project data and process details of different trades like mechanical, electrical, plumbing, fire protection, architecture, civil, and structural engineering to create a functionally coordinated model. The model is envisioned to be relevant in future as the project expands to cater to future requirements. The framework is conceptualized and designed to avoid any inter-trade clashes and promote efficient and safe construction making it manageable and scalable throughout the project lifecycle.
                      </p>
                      <p>
                      Here, BIM coordinators work on each construction detail to find errors and deviations across the construction workflows. The team works closely with project planners and construction engineers to exchange process-critical data inputs, component details, quantity charts, and raise RFIs and propose optimum solutions for BIM coordination and achieve highest order of process compliance and collaborative value in the project.
                      </p>
                      <p>
                      To make the complex construction industry projects more predictable and controllable, automatic clash detection is being increasingly used to make the process of coordination more standardized and consistent. This makes the overall process more organized and allows architects and engineers to identify and resolve clashes and prepare detailed clash reports based on critical BIM data and functional insights. This approach helps in achieving the best solution to the situation without having to deal with the manual hassles, errors, and delays. Further, promoting more time-efficient and leaner practices for improving construction modeling, implementation, and overall results for complex projects.
                      </p>

                      <h3 className="m-t30 sx-tilte">
                      BIM Coordination Tools for 3D Visualization and Resolution of Clashes
                      </h3>
                      <p>
                      There are a number of clash detection and resolution tools and software to help BIM coordinators align and manage BIM workflows and produce trade-coordinated models to validate and optimize project value. Here are some of the most commonly used BIM coordination tools that help enhance construction process quality and accuracy.
                      </p>
                      <ul>
                        <li>BIM 360</li>
                        <li>BIM 360 GLUE</li>
                        <li>Autodesk Navisworks</li>
                        <li>BIM Track</li>
                        <li>Project management platforms like Procore, Egnyte, Ecomm, CoConstruct, PlanGrid, etc.</li>
                      </ul>




                      <h4 className="m-t30 sx-tilte">
                        Professional Interior Design Service
                      </h4>
                      <div className="single-service-list">
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <ul className="list-angle-right anchor-line">
                              <li>
                                <NavLink to={"/services-1"}>
                                  We provide high quality design services.
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to={"/services-1"}>
                                  We are confident about our projects.
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to={"/services-1"}>
                                  A good Work Environment For work
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to={"/services-1"}>
                                  Communication Skills to getting in touch
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to={"/services-1"}>
                                  {" "}
                                  Renovations Benefit of Service
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <ul className="list-angle-right anchor-line">
                              <li>
                                <NavLink to={"/services-1"}>
                                  Sustainability and modern tech.
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to={"/services-1"}>
                                  A Career Overview opportunity Available
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to={"/services-1"}>
                                  Scientific Skills For getting a better result
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to={"/services-1"}>
                                  {" "}
                                  Historic Renovations and Restorations.
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to={"/services-1"}>
                                  Project on time and Latest Design
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* <div className="sx-media sx-img-effect zoom-slow">
                        <div className="sx-thum-bx sx-img-overlay1 sx-img-effect yt-thum-box">
                          <img
                            src="https://img.youtube.com/vi/Oy2QIiSQT2U/0.jpg"
                            alt=""
                          />
                          <NavLink
                            to={"#"}
                            className="play-now"
                            data-toggle="modal"
                            data-target="#myModal6"
                          >
                            <i className="icon fa fa-play" />
                            <span className="ripple" />
                          </NavLink>
                        </div>
                      </div> */}
                    </div>
                    <div
                      className="sx-accordion acc-bg-gray m-b30"
                      id="accordion5"
                    >
                      <div className="panel sx-panel">
                        <div className="acod-head acc-actives">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseOne5"
                              data-parent="#accordion5"
                            >
                              What is BIM Coordination, and why is it essential for construction projects?
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div
                          id="collapseOne5"
                          className="acod-body collapse show"
                        >
                          <div className="acod-content p-a15">
                            <p>
                              {" "}
                              BIM Coordination involves the collaborative use of Building Information Modeling to detect and resolve design conflicts early in the project. This process ensures that all disciplines - architectural, structural, mechanical, and electrical - work together seamlessly, reducing errors, delays, and rework on-site.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTwo5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How does BIM Coordination improve project efficiency?
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseTwo5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                            BIM Coordination enhances project efficiency by facilitating real-time collaboration among stakeholders. It allows for early detection of clashes, optimizing the design before construction begins, which leads to fewer delays, reduced costs, and improved project timelines.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseThree5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              What is the role of Autodesk Revit in the BIM Coordination process?
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseThree5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                            Autodesk Revit plays a significant role in the BIM Coordination process by providing a platform for creating detailed Revit models that integrate architectural, structural, and MEP elements. These models allow BIM experts to detect clashes, generate collision reports, and streamline communication among team members. Revit's advanced tools and features contribute to the overall success of the coordination process, ensuring that the construction project is executed efficiently and accurately.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 m-b30">
                        <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                          <div className="sx-icon-box-lg inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                            <span className="icon-cell sx-text-primary">
                              <i className="flaticon-door" />
                            </span>
                          </div>
                          <div className="icon-content">
                            <h4 className="sx-tilte">Decoration</h4>
                            <p>
                            Maximize the potential of your architectural designs with our state-of-the-art 3D BIM modeling solutions. Our models help you visualize, analyze, and execute your project with confidence, ensuring every detail is captured with precision.
                            </p>
                            <NavLink
                              to={"/services-1"}
                              className="site-button-link"
                            >
                              Read More
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 m-b30">
                        <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                          <div className="sx-icon-box-lg inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                            <span className="icon-cell sx-text-primary">
                              <i className="flaticon-ruler-1" />
                            </span>
                          </div>
                          <div className="icon-content">
                            <h4 className="sx-tilte">Solution</h4>
                            <p>
                            Unlock the full potential of BIM with our expert 3D modeling services. We transform 2D plans, sketches, and point clouds into comprehensive 3D models, providing the foundation for efficient design, analysis, and construction.
                            </p>
                            <NavLink
                              to={"/services-1"}
                              className="site-button-link"
                            >
                              Read More
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* GALLERY CONTENT END */}
            </div>
          </div>
          {/* SECTION CONTENT END  */}
        </div>
        <div className="modal fade" id="myModal6" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <ReactPlayer url="https://www.youtube.com/watch?v=Oy2QIiSQT2U" />
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default ServiceDetail;
