import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: '01',
        title: 'AutoCAD Civil 3D',
        description: 'AutoCAD Civil 3D provides civil engineering design and documentation workflows, offering tools for drafting, designing, and managing infrastructure projects like roads, land development, and water systems.',
    },
    {
        count: '02',
        title: 'Civil 3D',
        description: 'Civil 3D is a comprehensive design and documentation software used for civil engineering projects like transportation and water management. It streamlines workflows by integrating design and ensuring accuracy in civil infrastructure projects from concept to construction.',
    },
    {
        count: '03',
        title: 'Revit',
        description:'Revit is a powerful BIM software that allows architects, engineers, and contractors to design, model, and document building projects in a unified environment. It supports collaboration, enabling multidisciplinary teams to work efficiently on 3D models with real-time updates.',
    },
    {
        count: '04',
        title: 'Autodesk Navisworks',
        description: 'Autodesk Navisworks enables project review, clash detection, and coordination for BIM workflows. It integrates 3D models and data from multiple sources, allowing teams to identify conflicts, visualize designs, and streamline construction planning for more efficient project delivery.',
    },
    {
        count: '05',
        title: 'Recap',
        description: 'Autodesk Recap converts reality capture data—like laser scans and photos—into accurate 3D models or point clouds. This allows professionals to analyze, visualize, and integrate existing conditions into BIM workflows, improving accuracy in renovation, restoration, and construction projects.',
    },
    {
        count: '06',
        title: 'Cyclone',
        description: 'A cyclone is a large-scale, rotating storm system characterized by strong winds, low atmospheric pressure, and heavy rainfall. Typically forming over warm ocean waters, cyclones can cause significant damage due to flooding, storm surges, and high winds, often impacting coastal regions and islands.',
    },
    {
        count: '07',
        title: 'Micro Station V8 or V8i',
        description: 'MicroStation V8/V8i is a CAD software by Bentley Systems for 2D and 3D design, used in architecture and engineering to model, document, and visualize infrastructure projects with precision and versatility.',
    },
    {
        count: '08',
        title: 'Arc Gis 10.3',
        description: 'ArcGIS 10.3 by Esri is a GIS software version offering spatial data analysis, mapping, and geoprocessing tools for visualizing and managing geographic information, supporting both desktop and enterprise-level geospatial applications.',
    },
    {
        count: '09',
        title: 'Global Mapper',
        description: 'Global Mapper is a GIS application for spatial data manipulation, analysis, and visualization, widely used in surveying, mapping, and environmental fields for handling multiple data formats and creating high-quality maps.',
    },

]

var bgimg1 = require('./../../images/background/bg-5.png');
var bgimg2 = require('./../../images/background/cross-line2.png');

class WhatWeDo2 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full  mobile-page-padding bg-white  p-t80 p-b50 bg-repeat overflow-hide" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                    <div className="container">
                        <div className="large-title-block text-center">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-center">
                                    <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                        <h3 className="sep-line-one">What We Do</h3>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                        </div>
                        <div className="section-content">
                            <div className="row number-block-two-outer">
                                {services.map((item, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12 m-b30" key={index}>
                                        <div className="number-block-two animate-in-to-top bdr-gray-light bdr-solid bdr-1">
                                            <div className="figcaption bg-white  p-a30">
                                                <h4 className="m-t0">{item.title}</h4>
                                                <p>{item.description}</p>
                                                <NavLink to={"/services-detail"} className="site-button-link">Read More</NavLink>
                                                <div className="figcaption-number animate-in-to-top-content">
                                                    <span>{item.count}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default WhatWeDo2;