import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Switcher from '../Elements/Switcher';
import emailjs from '@emailjs/browser';

const Footer = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_wh5nljt', 'template_bwv821j', form.current, {
        publicKey: 'QXf4uhQhNlnBiO5zw',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <footer className="site-footer footer-large footer-dark footer-wide">
        {/* FOOTER BLOCKS START */}
        <div className="footer-top overlay-wraper">
          <div className="overlay-main" />
          <div className="container">
            <div className="row">
              {/* ABOUT COMPANY */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget widget_about">
                  <div className="logo-footer clearfix p-b15">
                    <NavLink to={"./"}>
                      <img src={require('./../../images/logo-light.png')} alt="Inteshape" />
                    </NavLink>
                  </div>
                  <ul className="social-icons sx-social-links">
                    {/* <li><a href="https://www.behance.net/" className="fa fa-behance" target="_blank"></a></li> */}
                    <li><a href="mailto:gmrengineeringservices.01@gmail.com" className="fa fa-google" target="_blank"></a></li>
                    <li><a href="https://www.linkedin.com/company/105017351/admin/dashboard/" className="fa fa-linkedin" target="_blank"></a></li>
                    <li><a href="https://www.instagram.com/gmrengineeringservices/profilecard/?igsh=MWNqd2l3YWgzcHR6aw==" className="fa fa-instagram" target="_blank"></a></li>
                  </ul>
                </div>
              </div>
              {/* USEFUL LINKS */}
              <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                <div className="widget widget_services inline-links">
                  <h5 className="widget-title">Useful links</h5>
                  <ul>
                    <li><NavLink to={"/about-1"}>About</NavLink></li>
                    <li><NavLink to={"/services-1"}>Services</NavLink></li>
                    {/* <li><NavLink to={"/project-grid-3-columns"}>Projects</NavLink></li> */}
                    <li><NavLink to={"/contact-us"}>Contact Us</NavLink></li>
                  </ul>
                </div>
              </div>
              {/* CONTACT US */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget widget_address_outer">
                  <h5 className="widget-title">Contact Us</h5>
                  <ul className="widget_address">
                    {/* <li>Jaipur</li> */}
                    <li>gmrengineeringservices.01@gmail.com</li>
                    <li>(+91) 9784189069</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="call-to-action-wrap">
              <div className="row">
                <div className="col-lg-7 col-md-6">
                  <div className="call-to-action-left">
                    <h5 className="text-uppercase m-b10 m-t0">Subscribe to our newsletter!</h5>
                    <span>Never Miss Anything From GMR Engineering Service.com By Signing Up To Our Newsletter.</span>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="call-to-action-right">
                    <div className="widget_newsletter">
                      <div className="newsletter-bx">
                        <form ref={form} onSubmit={sendEmail} role="search" method="post">
                          <div className="input-group">
                            <input name="user_email" className="form-control" placeholder="ENTER YOUR EMAIL" type="text" />
                            <span className="input-group-btn">
                              <button type="submit" className="site-button"><i className="fa fa-paper-plane-o" /></button>
                            </span>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FOOTER COPYRIGHT */}
        <div className="footer-bottom overlay-wraper">
          <div className="overlay-main" />
          <div className="container">
            <div className="row">
              <div className="sx-footer-bot-left">
                <span className="copyrights-text">© 2024 GMR Engineering Service.com.</span>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Switcher />
    </>
  );
};

export default Footer;
