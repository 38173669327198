import React, { useRef } from "react";
import Header3 from "./../Common/Header3";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import emailjs from '@emailjs/browser';

var bnrimg = require("./../../images/banner/6.jpg");



const ContactUs = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_wh5nljt', 'template_y7qihym', form.current, {
        publicKey: 'QXf4uhQhNlnBiO5zw',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
      e.target.reset();
  };

  return (
    <>
      <Header3 />
      <div className="page-content">
        <Banner
          title="Contact Us Form"
          pagename="Contact us"
          description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized."
          bgimage={bnrimg}
        />
        <div className="section-full p-tb80 inner-page-padding">
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <form
                    ref={form}
                    onSubmit={sendEmail}
                    className="contact-form cons-contact-form bg-gray p-a30"
                  >
                    <div className="contact-one">
                      <div className="section-head">
                        <div className="sx-separator-outer separator-left">
                          <div
                            className="sx-separator bg-white bg-moving bg-repeat-x"
                            style={{
                              backgroundImage:
                                "url(images/background/cross-line2.png)",
                            }}
                          >
                            <h3 className="sep-line-one">Form</h3>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          name="user_name"
                          type="text"
                          required
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="user_email"
                          type="email"
                          className="form-control"
                          required
                          placeholder="Email"
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          rows={4}
                          className="form-control "
                          required
                          placeholder="Message"
                          defaultValue={""}
                        />
                      </div>
                      <div className="text-right">
                        <button
                          name="submit"
                          type="submit"
                          value="Send"
                          className="site-button btn-half"
                        >
                          <span> submit</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div
                    className="contact-info block-shadow bg-white bg-center p-a40"
                    style={{
                      backgroundImage: "url(images/background/bg-map.png)",
                    }}
                  >
                    <div>
                      <div className="section-head">
                        <div className="sx-separator-outer separator-left">
                          <div
                            className="sx-separator bg-white bg-moving bg-repeat-x"
                            style={{
                              backgroundImage:
                                "url(images/background/cross-line2.png)",
                            }}
                          >
                            <h3 className="sep-line-one">Info</h3>
                          </div>
                        </div>
                      </div>
                      <div className="sx-icon-box-wraper left p-b30">
                        <div className="icon-xs">
                          <i className="fa fa-phone" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0">Phone number</h5>
                          <p>(+91)9784189069</p>
                        </div>
                      </div>
                      <div className="sx-icon-box-wraper left p-b30">
                        <div className="icon-xs">
                          <i className="fa fa-envelope" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0">Email address</h5>
                          <p>gmrengineeringservices.01@gmail.com
                          </p>
                        </div>
                      </div>
                      {/* <div className="sx-icon-box-wraper left">
                        <div className="icon-xs">
                          <i className="fa fa-map-marker" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0">Address info</h5>
                          <p>
                          Jaipur
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="gmap-outline">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0",
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent
              lat={34.07328}
              lng={-118.25141}
              text={<i className="fa fa-map-marker" />}
            />
          </GoogleMapReact>
        </div> */}
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
