import React, { Component } from 'react';
// import './MissionSection.css';

var bgimg1 = require('./../../images/background/bg-5.png');
var bgimg2 = require('./../../images/background/cross-line2.png');

class MissionSection extends Component {
  render() {
    return (
      <div
        className="mission-section section-full mobile-page-padding bg-white p-t80 p-b50 bg-repeat overflow-hide"
        style={{ backgroundImage: 'url(' + bgimg1 + ')' }}
      >
        <div className="mission-content">
        <div className="large-title-block text-center">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-center">
                                    <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                        <h3 className="sep-line-one">Mission</h3>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                        </div>
          
          <h1 className="sx-title">Built on trust, focused on quality</h1>
          <p className="mission-description">
            At GMR, we deliver cutting-edge construction technology solutions that drive efficiency, precision, and collaboration. By fostering strong partnerships and building trust with owners, architects, engineers, and contractors, we ensure seamless project execution. Our innovative tools and platforms are designed to optimize outcomes, reduce costs, and promote teamwork, empowering our partners to succeed together in reshaping the future of construction.
          </p>
        </div>
        <div className="mission-video">
          <video autoPlay loop muted>
            <source src={require('./../../images/video/mission.mp4')} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  }
}

export default MissionSection;
