import React, { Component } from 'react';
// import './VisionObjectives.css';

var bgimg1 = require('./../../images/background/bg-5.png');
var bgimg2 = require('./../../images/background/cross-line2.png');

class VisionObjectives extends Component {
  render() {
    return (
      <div className="vision-objectives-container section-full  mobile-page-padding bg-white  p-t80 p-b50 bg-repeat overflow-hide" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
        <div className="large-title-block text-center">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-center">
                                    <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                        <h3 className="sep-line-one">Our Vision and Objectives</h3>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                        </div>

        <div className="objectives-cards">
          <div className="card">
            <div className="icon technological-icon"></div>
            <h3>Technological Leadership</h3>
            <p>
            Aiming to pioneer engineering services, we leverage advanced tools and technologies to deliver fast, innovative solutions, establishing ourselves as leaders in the technology landscape.
            </p>
          </div>
          <div className="card">
            <div className="icon comprehensive-icon"></div>
            <h3>Comprehensive Services</h3>
            <p>
            We provide all-encompassing support to engineering companies, addressing diverse needs across multiple domains to help them achieve high-quality results effortlessly.
            </p>
          </div>
          <div className="card">
            <div className="icon innovation-icon"></div>
            <h3>Continuous Innovation</h3>
            <p>
            Innovation drives us to enhance services with advanced methods, ongoing research, and development, fostering progress and transformation within the engineering sector.
            </p>
          </div>
          <div className="card">
            <div className="icon partnership-icon"></div>
            <h3>Cooperative Partnership</h3>
            <p>
            Collaborating closely with engineering companies, we understand their challenges, meet unique requirements, and support their projects throughout every phase.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default VisionObjectives;


































// import React from 'react';

// class VisionAndObjectives extends React.Component {
//     render() {
//       return (
//     <div className="vision-and-objectives">
//       <h1>Our Vision and Objectives</h1>
//       <div className="vision-cards">
//         <div className="vision-card">
//           <img src="path/to/gear-icon.svg" alt="Gear Icon" />
//           <h2>Technological Leadership</h2>
//           <p>
//             Our ultimate goal is to become the pioneer of technology
//             engineering services, and we aim to take advantage of the latest
//             developments and tools to deliver fast and innovative solutions
//             to engineering companies, using various technologies.
//           </p>
//         </div>
//         <div className="vision-card">
//           <img src="path/to/check-icon.svg" alt="Check Icon" />
//           <h2>Comprehensive Services</h2>
//           <p>
//             We strive to provide comprehensive assistance and services to
//             engineering companies across multiple axes to meet their
//             different needs, enabling them to achieve their goals with ease
//             and with the highest possible quality.
//           </p>
//         </div>
//         <div className="vision-card">
//           <img src="path/to/puzzle-icon.svg" alt="Puzzle Icon" />
//           <h2>Continuous Innovation</h2>
//           <p>
//             Innovation is our main driver, we constantly strive to strengthen
//             our services using advanced methods, continue research and
//             development, and our goal is to be a catalyst for change and
//             progress in the engineering field.
//           </p>
//         </div>
//         <div className="vision-card">
//           <img src="path/to/handshake-icon.svg" alt="Handshake Icon" />
//           <h2>Cooperative Partnership</h2>
//           <p>
//             We offer ourselves as collaborative partners with different
//             engineering companies, our goal is to work closely with them,
//             understand their needs, challenges facing them, meet their
//             unique requirements, and provide support and assistance to
//             designers throughout the life cycle of the project.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };
// }

// export default VisionAndObjectives;
