import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: '01',
        title: 'MEPFP Services',
        description: 'Revit MEP modeling , drafting clash coordination design solutions for mechanical, electrical, plumbing, and fire protection components.',
        image: require('./../../images/services/mepfp.png') // Example image path
    },
    {
        count: '02',
        title: 'Structural BIM services',
        description: 'Structural design and analysis, modeling, drawing, steel, owners, contractors, architects and designers detailing.',
        image: require('./../../images/services/structural-bim.png') // Example image path
    },
    {
        count: '03',
        title: 'Architectural BIM',
        description: 'Modeling, drawings, point cloud scan, floor plans, as-built, construction documents, 3D renderings and visualization.',
        image: require('./../../images/services/architectural-bim.png') // Example image path
    },
    {
        count: '04',
        title: '4D Scheduling Simulation',
        description: 'Construction scheduling services for owners, contractors, architects and designers.',
        image: require('./../../images/services/scheduling-simulation.png') // Example image path
    },
    {
        count: '05',
        title: '5D BIM: Quantity Take-Offs',
        description: 'Cost estimation, Quantity take-off and budgeting.',
        image: require('./../../images/services/quantity-takeoffs.png') // Example image path
    },
    {
        count: '06',
        title: 'Revit Family Creation',
        description: 'Customized BIM Content Creation (Revit Family) for product manufacturers, architect, engineers.',
        image: require('./../../images/services/revit-family.png') // Example image path
    },
    {
        count: '07',
        title: 'CAD-to-BIM Conversion',
        description: '2D CAD draft, layouts, hand draft, model into Revit BIM model.',
        image: require('./../../images/services/cad-to-bim.png') // Example image path
    },
    {
        count: '08',
        title: 'Clash Detection & Report Generation',
        description: 'Generating clash report and resolving inter/intra-disciplinary clashes.',
        image: require('./../../images/services/clash-detection.png') // Example image path
    },
    {
        count: '09',
        title: '3D Scan to BIM | CAD',
        description: 'Point cloud data into BIM model conversion for renovation or retrofit projects.',
        image: require('./../../images/services/scan-to-bim.webp') // Example image path
    },
];

var bgimg1 = require('./../../images/background/bg-5.png');
var bgimg2 = require('./../../images/background/cross-line2.png');

class Services6 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding bg-gray p-t80 p-b50 bg-repeat overflow-hide" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                    <div className="container">
                        <div className="large-title-block text-center">
                            <div className="section-head">
                                <div className="sx-separator-outer separator-left">
                                    <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                        <h3 className="sep-line-one">Services</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="row number-block-two-outer">
                                {services.map((item, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12 m-b30" key={index}>
                                        <div className="number-block-two animate-in-to-top bdr-gray-light bdr-solid bdr-1">
                                            <div className="figcaption bg-white p-a30">
                                             <img src={item.image} alt={item.title} className="service-image m-b10" /> 
                                                <h4 className="m-t0">{item.title}</h4>
                                                <p>{item.description}</p>
                                                <NavLink to={"/services-detail"} className="site-button-link">Read More</NavLink>
                                                <div className="figcaption-number animate-in-to-top-content">
                                                    <span>{item.count}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Services6;
