import React from "react";


var bgimg1 = require("./../../images/background/bg-5.png");
// var bgimg2 = require("./../../images/background/bg-2.jpg");
var bgimg3 = require("./../../images/background/cross-line2.png");


class WhatWeDo6 extends React.Component {
  render() {
    return (
      <>
  
        <div
          className="section-full  mobile-page-padding bg-white  p-t80 p-b30 bg-repeat overflow-hide"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container right-half-bg-image-outer">
            {/* <div
              className="right-half-bg-image bg-parallax bg-fixed bg-top-right"
              data-stellar-background-ratio={0}
              style={{ backgroundImage: "url(" + bgimg2 + ")" }}
            /> */}
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg3 + ")" }}
                >
                  <h3 className="sep-line-one">What We do</h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="row number-block-one-outer justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                 
                    <img  style={{ height: '200px', width: '350px' }} src={require("./../../images/pic1.png")} alt="" />
                    <div className="figcaption bg-white text-center p-a20">
                      <h4 className="m-a0">Floor Plan</h4>
                    </div>
                  
                  
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                
                    <img  style={{ height: '200px', width: '350px' }}  style={{ height: '200px', objectFit: 'cover' }} src={require("./../../images/pic2.png")} alt="" />
                    <div className="figcaption bg-white text-center p-a20">
                      <h4 className="m-a0">Elevation Design</h4>
                    </div>
                   
                
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                 
                    <img  style={{ height: '200px', width: '350px' }} src={require("./../../images/pic3.png")} alt="" />
                    <div className="figcaption bg-white text-center p-a20">
                      <h4 className="m-a0">Structure of Point Cloud</h4>
                    </div>
                    
                 
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                 
                    <img  style={{ height: '200px', width: '350px' }} src={require("./../../images/pic4.png")} alt="" />
                    <div className="figcaption bg-white text-center p-a20">
                      <h4 className="m-a0">Calbe tray model of a Building</h4>
                    </div>
                    
                 
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                  
                    <img   style={{ height: '200px', width: '350px' }} className="img" src={require("./../../images/pic5.png")} alt="" />
                    <div className="figcaption bg-white text-center p-a20">
                      <h4 className="m-a0">Kitchen exhaust system</h4>
                    </div>
                   
                
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                  
                    <img  style={{ height: '170px', width: '350px' }} src={require("./../../images/pic6.png")} alt="" />
                    <div className="figcaption bg-white text-center p-a20">
                      <h4 className="m-a0">Building Mechanical Services</h4>
                    </div>
                    
               
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                 
                    <img  style={{ height: '200px', width: '350px' }} src={require("./../../images/pic7.png")} alt="" />
                    <div className="figcaption bg-white text-center p-a20">
                      <h4 className="m-a0">3D Model of cable tray connect</h4>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                 
                    <img  style={{ height: '200px', width: '350px' }} className="img" src={require("./../../images/pic8.png")} alt="" />
                    <div className="figcaption bg-white text-center p-a20">
                      <h4 className="m-a0">Bathroom Group Plumbing Connection</h4>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                 
                 <img  style={{ height: '200px', width: '350px' }} className="img" src={require("./../../images/pic9.png")} alt="" />
                 <div className="figcaption bg-white text-center p-a20">
                   <h4 className="m-a0">Unit Room Bathroom Plumbing Group</h4>
                 </div>
             </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WhatWeDo6;
